<template>
  <div class="box">
    <div class="breadcrumb">
      <el-breadcrumb>
        <el-breadcrumb-item>{{route_name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="router_container">
      <div class="router_view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      route_name: ""
    };
  },
  methods: {
    back() {
      history.back();
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.route_name = route.name;
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>

</style>